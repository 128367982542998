import React from "react";

const PremiumServices = () => {
  return (
    <section id="team" className>
      {/* =============== container =============== */}
      <div className="container">
        <div className="row">
          <div className="title">
            <h2>Premium Services</h2>
            <p>
              We specialize in the following services with plenty of experience.
            </p>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow fadeInDown animated"
            data-wow-delay=".1s"
          >
            <div className="timg">
              <img
                src="assets/img/logoDigitizingImage.jpg"
                alt="Left Chest Logo"
                width="262.5"
                height="262.5"
              />
            </div>
            <div className="t-box">
              <h3>Left Chest Logo Digitizing</h3>
              <span>
                The logo digitizing is a process in which the teams of
                professionals design a customized logo...
              </span>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow fadeInUp animated"
            data-wow-delay=".2s"
          >
            <div className="timg">
              <img src="assets/img/cap-logo.jpg" width="262.5" height="262.5" />
            </div>
            <div className="t-box">
              <h3>Cap Digitizing</h3>
              <span>
                The digitizing of the cap is a tricky procedure as it involves
                designing on a curved shape...
              </span>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow fadeInDown animated"
            data-wow-delay=".3s"
          >
            <div className="timg">
              <img
                src="assets/img/vectorart/pic5.jpg"
                width="262.5"
                height="262.5"
              />
            </div>
            <div className="t-box">
              <h3>Raster To Vector</h3>
              <span>
                Encapsulated Postscript Vector graphics, a file extension for a
                graphics file used for Digitizing...
              </span>
            </div>
          </div>

          <div
            className="col-xs-12 col-sm-6 col-md-3 wow fadeInDown animated"
            data-wow-delay=".3s"
          >
            <div className="timg">
              <img
                src="assets/img/customn-embroidery.jpg"
                width="262.5"
                height="262.5"
              />
            </div>
            <div className="t-box">
              <h3>Custom Embroidery</h3>
              <span>
                Custom Embroidery Vector graphics, a file extension for a
                graphics file used for vector art...
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* =============== container end =============== */}
    </section>
  );
};

export default PremiumServices;
