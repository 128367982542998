import React from "react";
import "./PricingCard.css";
import data from "./data.json";
export const PricingCard = () => {
  return (
    <section className="ftco-section bg-light">
      <div className="container">
        {/* <div className="row justify-content-center pb-5 mb-3">
          <div className="col-md-7 heading-section text-center ftco-animate fadeInUp ftco-animated">
            <h2>Affordable Packages</h2>
          </div>
        </div> */}
        <div className="row">
          {data?.map((_elem) => (
            <div
              key={_elem.id}
              className="col-md-3 ftco-animate fadeInUp ftco-animated"
            >
              <div className="block-7">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${_elem.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    border: "1px solid gray",
                  }}
                />
                <div className="text-center p-4">
                  <span className="excerpt d-block">{_elem.title}</span>
                  <span className="price">
                    <sup>$</sup> <span className="number">{_elem.price}</span>{" "}
                    <sub>/design</sub>
                  </span>
                  <ul className="pricing-text mb-5">
                    {_elem.detail?.map((checklist, idx) => (
                      <li key={idx}>
                        <span className="fa fa-check mr-2" />
                        {checklist}
                      </li>
                    ))}
                  </ul>
                  <a
                    href="mailto:info@crowndigitize.com"
                    className="btn btn-primary d-block px-2 py-3"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="col-md-4 ftco-animate fadeInUp ftco-animated">
            <div className="block-7">
              <div
                className="img"
                style={{
                  backgroundImage:
                    "url(https://via.placeholder.com/350x280/87CEFA/000000)",
                }}
              />
              <div className="text-center p-4">
                <span className="excerpt d-block">Business</span>
                <span className="price">
                  <sup>$</sup> <span className="number">79</span>{" "}
                  <sub>/mos</sub>
                </span>
                <ul className="pricing-text mb-5">
                  <li>
                    <span className="fa fa-check mr-2" />5 Dog Walk
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />3 Vet Visit
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />3 Pet Spa
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />
                    Free Supports
                  </li>
                </ul>
                <a href="#" className="btn btn-primary d-block px-2 py-3">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 ftco-animate fadeInUp ftco-animated">
            <div className="block-7">
              <div
                className="img"
                style={{
                  backgroundImage:
                    "url(https://via.placeholder.com/350x280/FF7F50/000000)",
                }}
              />
              <div className="text-center p-4">
                <span className="excerpt d-block">Ultimate</span>
                <span className="price">
                  <sup>$</sup> <span className="number">109</span>{" "}
                  <sub>/mos</sub>
                </span>
                <ul className="pricing-text mb-5">
                  <li>
                    <span className="fa fa-check mr-2" />5 Dog Walk
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />3 Vet Visit
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />3 Pet Spa
                  </li>
                  <li>
                    <span className="fa fa-check mr-2" />
                    Free Supports
                  </li>
                </ul>
                <a href="#" className="btn btn-primary d-block px-2 py-3">
                  Get Started
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
