import React from "react";

const Gallery = () => {
  return (
    <section id="gallery" className="wow flipInX animated" data-wow-delay=".5s">
      <div className="title">
        <h2>Gallery</h2>
        <p>we are awesome, check some interesting facts</p>
      </div>
      <div className="big-demo go-wide" data-js-module="filtering-demo">
        <div className="container">
          <div className="row">
            <div className="filter-button-group button-group js-radio-button-group">
              <button className="button is-checked" data-filter=".VectorArt">
                VectorArt
              </button>
              <button className="button" data-filter=".DigitizingArt">
                Digitizing Art
              </button>
            </div>
          </div>
        </div>
        <div className="grid baguetteBoxThree gallery">
          <div
            className="element-item transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic1.jpg">
              <img src="assets/img/vectorart/pic1.jpg" alt="" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic2.jpg">
              <img src="assets/img/vectorart/pic2.jpg" alt="" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic3.jpg">
              <img src="assets/img/vectorart/pic3.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic4.jpg">
              <img src="assets/img/vectorart/pic4.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic5.jpg">
              <img src="assets/img/vectorart/pic5.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic6.jpg">
              <img src="assets/img/vectorart/pic6.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic7.jpg">
              <img src="assets/img/vectorart/pic7.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic8.jpg">
              <img src="assets/img/vectorart/pic8.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic9.jpg">
              <img src="assets/img/vectorart/pic9.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic10.jpg">
              <img src="assets/img/vectorart/pic10.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic11.jpg">
              <img src="assets/img/vectorart/pic11.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic12.jpg">
              <img src="assets/img/vectorart/pic12.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic13.jpg">
              <img src="assets/img/vectorart/pic13.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic14.jpg">
              <img src="assets/img/vectorart/pic14.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition VectorArt "
            data-category="post-transition"
          >
            <a href="assets/img/vectorart/pic15.jpg">
              <img src="assets/img/vectorart/pic15.jpg" />
              <div className="hover-img">
                <h2>Vector Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          {/* **************************New images************************** */}
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 29.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 29.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 28.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 28.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 27.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 27.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 26.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 26.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 25.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 25.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 24.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 24.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 23.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 23.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 22.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 22.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 21.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 21.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 20.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 20.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 15.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 15.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 18.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 18.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 17.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 17.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 14.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 14.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 19.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 19.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 16.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 16.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 12.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 12.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 13.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 13.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 11.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 11.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 10.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 10.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 9.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 9.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 8.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 8.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 7.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 7.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 6.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 6.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 5.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 5.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 4.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 4.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 3.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 3.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 2.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 2.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/NewAssests/Digitizing/img 1.jpeg">
              <img src="assets/img/NewAssests/Digitizing/img 1.jpeg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          {/* **************************************************** */}
          <div
            className="element-item transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing1.jpg">
              <img src="assets/img/Digitizing/digitizing1.jpg" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing2.jpg">
              <img src="assets/img/Digitizing/digitizing2.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing3.jpg">
              <img src="assets/img/Digitizing/digitizing3.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing4.jpg">
              <img src="assets/img/Digitizing/digitizing4.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing5.jpg">
              <img src="assets/img/Digitizing/digitizing5.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing6.jpg">
              <img src="assets/img/Digitizing/digitizing6.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing7.jpg">
              <img src="assets/img/Digitizing/digitizing7.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing8.jpg">
              <img src="assets/img/Digitizing/digitizing8.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing9.jpg">
              <img src="assets/img/Digitizing/digitizing9.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing10.jpg">
              <img src="assets/img/Digitizing/digitizing10.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing11.jpg">
              <img src="assets/img/Digitizing/digitizing11.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing12.jpg">
              <img src="assets/img/Digitizing/digitizing12.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing13.jpg">
              <img src="assets/img/Digitizing/digitizing13.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing14.jpg">
              <img src="assets/img/Digitizing/digitizing14.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing15.jpg">
              <img src="assets/img/Digitizing/digitizing15.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing16.jpg">
              <img src="assets/img/Digitizing/digitizing16.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing17.jpg">
              <img src="assets/img/Digitizing/digitizing17.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing18.jpg">
              <img src="assets/img/Digitizing/digitizing18.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing19.jpg">
              <img src="assets/img/Digitizing/digitizing19.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing20.jpg">
              <img src="assets/img/Digitizing/digitizing20.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing21.jpg">
              <img src="assets/img/Digitizing/digitizing21.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing22.jpg">
              <img src="assets/img/Digitizing/digitizing22.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing23.jpg">
              <img src="assets/img/Digitizing/digitizing23.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing24.jpg">
              <img src="assets/img/Digitizing/digitizing24.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing25.jpg">
              <img src="assets/img/Digitizing/digitizing25.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
          <div
            className="element-item post-transition DigitizingArt "
            data-category="post-transition"
          >
            <a href="assets/img/Digitizing/digitizing26.jpg">
              <img src="assets/img/Digitizing/digitizing26.jpg" alt="" />
              <div className="hover-img">
                <h2>Digitizing Art</h2>
                <i className="fa fa-camera camera" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
