import React from "react";
import { FaBriefcase, FaHourglass } from "react-icons/fa";
const Container = () => {
  return (
    <section className="counter">
      {/* =============== container =============== */}
      <div className="container">
        <div className="row">
          <div className="title">
            <h2>Why Premium, check some interesting facts</h2>
          </div>
          <div
            className="col-xs-12 col-sm-2 col-md-2 wow fadeInUp animated"
            data-wow-delay=".1s"
          >
            <i className="fa fa-clock-o size" />
            <h2>24/5</h2>
            <span>Working Hours</span>
          </div>
          <div
            className="col-xs-12 col-sm-2 col-md-2 wow fadeInUp animated"
            data-wow-delay=".2s"
          >
            <i className="fa fa-money size" />
            <h2>$</h2>
            <span>Easy Payment</span>
          </div>
          <div
            className="col-xs-12 col-sm-2 col-md-2 wow fadeInUp animated"
            data-wow-delay=".3s"
          >
            {/* <i className="fa-thin fa-hourglass-clock size"/> */}
            <FaHourglass className="size" />
            <h2>No</h2>
            <span>Wait</span>
          </div>
          <div
            className="col-xs-12 col-sm-2 col-md-2 wow fadeInUp animated"
            data-wow-delay=".4s"
          >
            <i className="fa fa-globe size" />
            <h2>Clients</h2>
            <span>Worldwide</span>
          </div>
          <div
            className="col-xs-12 col-sm-2 col-md-2 wow fadeInUp animated"
            data-wow-delay=".5s"
          >
            <i className="fa fa-rocket size" />
            <h2>133</h2>
            <span>Project Delivered</span>
          </div>
          <div
            className="col-xs-12 col-sm-2 col-md-2 wow fadeInUp animated"
            data-wow-delay=".6s"
          >
            {/* <i className="fa fa-rocket size" /> */}
            <FaBriefcase className="size" />
            <h2>11 years of </h2>
            <span>experience</span>
          </div>
        </div>
      </div>
      {/* =============== container end =============== */}
    </section>
  );
};

export default Container;
