import React from "react";
import { BlogHeader } from "../../components/BlogHeader";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

export const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <BlogHeader title={"Terms and Conditions"} />
      <section>
        <div className="container marg50">
          <div className="row">
            <p>
              Welcome to Premium Digitize! These terms and conditions outline
              the rules and regulations for the use of Premium Digitize Website,
              located at https://crowndigitize.com. By accessing this website we
              assume you accept these terms and conditions. Do not continue to
              use Premium Digitize if you do not agree to take all of the terms
              and conditions stated on this page. Our Terms and Conditions were
              created with the help of the Terms And Conditions Generator and
              the Free Terms &amp; Conditions Generator. The following
              terminology applies to these Terms and Conditions, Privacy
              Statement and Disclaimer Notice and all Agreements: “Client”,
              “You” and “Your” refers to you, the person log on this website and
              compliant to the Company’s terms and conditions. “The Company”,
              “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
              “Parties”, or “Us”, refers to both the Client and ourselves. All
              terms refer to the offer, acceptance and consideration of payment
              necessary to undertake the process of our assistance to the Client
              in the most appropriate manner for the express purpose of meeting
              the Client’s needs in respect of provision of the Company’s stated
              services, in accordance with and subject to, prevailing law of
              Netherlands. Any use of the above terminology or other words in
              the singular, plural, capitalization and/or he/she or they, are
              taken as interchangeable and therefore as referring to same.
            </p>
            <h2>Cookies</h2>
            <p>
              We employ the use of cookies. By accessing Premium Digitize, you
              agreed to use cookies in agreement with the Premium Digitize
              Privacy Policy. Most interactive websites use cookies to let us
              retrieve the user’s details for each visit. Cookies are used by
              our website to enable the functionality of certain areas to make
              it easier for people visiting our website. Some of our
              affiliate/advertising partners may also use cookies.
            </p>
            <h2>License</h2>
            <p>
              Unless otherwise stated, Premium Digitize and/or its licensors own
              the intellectual property rights for all material on Premium
              Digitize. All intellectual property rights are reserved. You may
              access this from Premium Digitize for your own personal use
              subjected to restrictions set in these terms and conditions. You
              must not:
            </p>
            <ul>
              <li>Republish material from Premium Digitize</li>
              <li>Sell, rent or sub-license material from Premium Digitize</li>
              <li>
                Reproduce, duplicate or copy material from Premium Digitize
              </li>
              <li>Redistribute content from Premium Digitize</li>
              <li>This Agreement shall begin on the date hereof.</li>
            </ul>
            <p>
              Parts of this website offer an opportunity for users to post and
              exchange opinions and information in certain areas of the website.
              Premium Digitize does not filter, edit, publish or review Comments
              prior to their presence on the website. Comments do not reflect
              the views and opinions of Premium Digitize,its agents and/or
              affiliates. Comments reflect the views and opinions of the person
              who post their views and opinions. To the extent permitted by
              applicable laws, Premium Digitize shall not be liable for the
              Comments or for any liability, damages or expenses caused and/or
              suffered as a result of any use of and/or posting of and/or
              appearance of the Comments on this website. Premium Digitize
              reserves the right to monitor all Comments and to remove any
              Comments which can be considered inappropriate, offensive or
              causes breach of these Terms and Conditions. You warrant and
              represent that:
            </p>
            <ul>
              <li>
                You are entitled to post the Comments on our website and have
                all necessary licenses and consents to do so;
              </li>
              <li>
                The Comments do not invade any intellectual property right,
                including without limitation copyright, patent or trademark of
                any third party;
              </li>
              <li>
                The Comments do not contain any defamatory, libelous, offensive,
                indecent or otherwise unlawful material which is an invasion of
                privacy
              </li>
              <li>
                The Comments will not be used to solicit or promote business or
                custom or present commercial activities or unlawful activity.
              </li>
            </ul>
            <p>
              You hereby grant Premium Digitize a non-exclusive license to use,
              reproduce, edit and authorize others to use, reproduce and edit
              any of your Comments in any and all forms, formats or media.
            </p>
            <h2>Hyperlinking to our Content</h2>
            <p>
              The following organizations may link to our Website without prior
              written approval:
            </p>
            <ul>
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </li>
              <li>
                System wide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site.
              </li>
            </ul>
            <p>
              These organizations may link to our home page, to publications or
              to other Website information so long as the link: (a) is not in
              any way deceptive; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products
              and/or services; and (c) fits within the context of the linking
              party’s site. We may consider and approve other link requests from
              the following types of organizations: commonly-known consumer
              and/or business information sources; dot.com community sites;
              associations or other groups representing charities; online
              directory distributors; internet portals; accounting, law and
              consulting firms; and educational institutions and trade
              associations. We will approve link requests from these
              organizations if we decide that: (a) the link would not make us
              look unfavorably to ourselves or to our accredited businesses; (b)
              the organization does not have any negative records with us; (c)
              the benefit to us from the visibility of the hyperlink compensates
              the absence of Premium Digitize; and (d) the link is in the
              context of general resource information. These organizations may
              link to our home page so long as the link: (a) is not in any way
              deceptive; (b) does not falsely imply sponsorship, endorsement or
              approval of the linking party and its products or services; and
              (c) fits within the context of the linking party’s site. If you
              are one of the organizations listed in paragraph 2 above and are
              interested in linking to our website, you must inform us by
              sending an e-mail to Premium Digitize. Please include your name,
              your organization name, contact information as well as the URL of
              your site, a list of any URLs from which you intend to link to our
              Website, and a list of the URLs on our site to which you would
              like to link. Wait 2-3 weeks for a response.
            </p>
            <h2>
              Approved organizations may hyperlink to our Website as follows:
            </h2>
            <ul>
              <li>By use of our corporate name; or</li>
              <li>
                By use of the uniform resource locator being linked to; or
              </li>
              <li>
                By use of any other description of our Website being linked to
                that makes sense within the context and format of content on the
                linking party’s site.
              </li>
              <li>
                No use of Premium Digitize logo or other artwork will be allowed
                for linking absent a trademark license agreement.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
