/* eslint-disable react/prop-types */
import React from "react";

export const BlogHeader = ({ title }) => {
  return (
    <header id="home" className="blog-header">
      {/* =============== container =============== */}
      <div className="container">
        <div className="header-content row">
          <h1>{title}</h1>
        </div>
      </div>
      {/* =============== container end =============== */}
    </header>
  );
};
