import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  console.log("location: ", location);
  // eslint-disable-next-line no-unused-vars
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      id="mainNav"
      className={`navbar navbar-default navbar-fixed-top ${
        scrollPosition < 300 ? "affix-top" : "affix"
      }`}
    >
      <div className="container">
        <div className="container-fluid">
          {/* Brand and toggle get grouped for better mobile display */}
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className="navbar-brand" style={{ paddingTop: "5px" }} href="#">
              <img src="assets/img/logo6.png" width={140} alt="Logo" />
            </a>
          </div>
          {/* Collect the nav links, forms, and other content for toggling */}
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  className="page-scroll"
                  href={location.pathname !== "/" ? "/#home" : "#home"}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href={location.pathname !== "/" ? "/#about" : "#about"}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href={location.pathname !== "/" ? "/#services" : "#services"}
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  className="page-scroll"
                  href={location.pathname !== "/" ? "/#gallery" : "#gallery"}
                >
                  Portfolio
                </a>
              </li>
              {/* <li>
                <a
                  className="page-scroll"
                  href={location.pathname !== "/" ? "/#blog" : "#blog"}
                >
                  Work
                </a>
              </li> */}
              <li>
                <a
                  className="page-scroll"
                  href={location.pathname !== "/" ? "/#contact" : "#contact"}
                >
                  Contact
                </a>
              </li>
              <li
                className={
                  location.pathname === "/terms-conditions" &&
                  location.hash === ""
                    ? "active"
                    : ""
                }
              >
                <a href="/terms-conditions" className="page-scroll">
                  Terms
                </a>
              </li>
              <li
                className={
                  location.pathname === "/privacy-policy" &&
                  location.hash === ""
                    ? "active"
                    : ""
                }
              >
                <a href="/privacy-policy" className="page-scroll">
                  Policy
                </a>
              </li>
            </ul>
          </div>
          {/* =============== navbar-collapse =============== */}
        </div>
      </div>
      {/* =============== container-fluid =============== */}
    </nav>
  );
};

export default Navbar;
