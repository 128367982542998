import React from "react";
import "./contactDetails.css";
const Footer = () => {
  return (
    <footer id="footer">
      {/* =============== container =============== */}
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <ul className="social-links">
              {/* <li><a className="wow fadeInUp animated" href="index.html#" style={{visibility: 'visible', animationName: 'fadeInUp'}}><i className="fa fa-facebook" /></a></li>
               <li><a data-wow-delay=".1s" className="wow fadeInUp animated" href="index.html#" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}><i className="fa fa-twitter" /></a></li>
               <li><a data-wow-delay=".2s" className="wow fadeInUp animated" href="index.html#" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}><i className="fa fa-google-plus" /></a></li>
               <li><a data-wow-delay=".4s" className="wow fadeInUp animated" href="index.html#" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp'}}><i className="fa fa-pinterest" /></a></li> */}
              <li>
                <a
                  data-wow-delay=".5s"
                  className="wow fadeInUp animated"
                  href="mailto:crowndigitize@gmail.com"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "fadeInUp",
                  }}
                >
                  <i className="fa fa-envelope" />
                </a>
              </li>
            </ul>
            <p className="copyright">
              © Copyright 2021 premiumdigitize Privacy Policy
            </p>
          </div>
          <div className="contactDetails col-xs-12 col-sm-6 col-md-6">
            <div
              data-wow-delay=".6s"
              className=" wow bounceIn  animated"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "bounceIn",
              }}
            >
              <section className="widget widget_text" id="text-15">
                <h3 className="widget-title">Office Location</h3>
                <div className="textwidget">
                  <p>
                    <div>
                      <a href="#">
                        <img src="assets/img/location.svg" />
                      </a>
                      Address: Asia, Pakistan
                    </div>

                    <div>
                      <a href="#">
                        <img src="assets/img/phone.svg" />
                      </a>
                      Call Us: (321) 988-5929
                    </div>
                    <div>
                      <a href="mailto: crowndigitize@gmail.com">
                        <img src="assets/img/email.svg" />
                        Email :crowndigitize@gmail.com
                      </a>
                    </div>
                    {/* E-mail: <Link mailto="crowndigitize@gmail.com" target="_blank">crowndigitize@gmail.com</Link> */}
                    {/* <Link to='mailto:crowndigitize@gmail.com' target="_blank">crowndigitize@gmail.com</Link> */}
                    <div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/crowndigitize/"
                      >
                        <img src="assets/img/websiteLink.svg" />
                        instagram :crowndigitize
                      </a>
                    </div>
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* =============== container end =============== */}
    </footer>
  );
};

export default Footer;
