import React from "react";

const AboutUs = () => {
  return (
    <section id="about" className>
      {/* =============== container =============== */}
      <div className="container">
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-6 wow fadeInDown animated"
            data-wow-delay=".5s"
          >
            <h2>About Us</h2>
            <p>
              Premium Digitize is a well known company for their best quality
              embroidery digitizing and vector digitizing. We provide world
              class services to our clients. A Company That Works on quality. We
              have a huge team of experienced professionals who are dedicated
              towards their work. We are committed to producing perfect quality.
              We have got professional expertise from embroidery digitizing to
              designing a logo. Though we are centralized at one place but our
              services can be availed from any part of the world be it USA or
              South Asia, we have got our grip all over the world.{" "}
            </p>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-6 wow fadeInRight animated"
            data-wow-delay=".5s"
          >
            <img src="assets/img/Embroidery-Service-3.jpg" alt="Embroidery" />
          </div>
        </div>
      </div>
      {/* =============== container end =============== */}
    </section>
  );
};

export default AboutUs;
