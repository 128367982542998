import React from "react";

const Contacts = () => {
  return (
    <section id="contact">
      {/* =============== container =============== */}
      <div className="container">
        <div className="row">
          <div className="title" style={{ color: "white" }}>
            <h2>Contact</h2>
            <p>Meet some of our lovely, passionate, positive people.</p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 wow bounceIn animated"
            data-wow-delay=".1s"
          >
            <form action="#" method="post">
              <div className="ajax-hidden">
                <div className="col-xs-12 col-sm-6 col-md-6 form-group wow fadeInUp animated">
                  <label htmlFor="c_name" className="sr-only">
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    className="form-control"
                    id="name"
                    required
                  />
                </div>
                <div
                  data-wow-delay=".1s"
                  className="col-xs-12 col-sm-6 col-md-6 form-group wow fadeInUp animated"
                >
                  <label htmlFor="c_email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    className="form-control"
                    id="email"
                    pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
                    required
                  />
                </div>
                <div
                  data-wow-delay=".2s"
                  className="col-xs-12 col-sm-12 col-md-12 form-group wow fadeInUp animated"
                >
                  <textarea
                    placeholder="Message"
                    rows={7}
                    name="description"
                    id="description"
                    className="form-control"
                    required
                    defaultValue={""}
                  />
                </div>
                <button
                  data-wow-delay=".3s"
                  className="btn btn-sm btn-block wow fadeInUp animated"
                  type="submit"
                >
                  Send Message
                </button>
              </div>
              <div className="ajax-response" />
            </form>
          </div>
        </div>
      </div>
      {/* =============== container end =============== */}
    </section>
  );
};

export default Contacts;
