import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { BlogHeader } from "../../components/BlogHeader";
import { PricingCard } from "../../components/PricingCard";
export const BuyNow = () => {
  return (
    <>
      <Navbar />
      <BlogHeader title={"Buy Now"} />
      <PricingCard />
      <Footer />
    </>
  );
};
