import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Home";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { PrivacyAndPolicy } from "./pages/PrivacyAndPolicy";
import { BuyNow } from "./pages/BuyNow";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="buy-now" element={<BuyNow />} />
        <Route path="terms-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyAndPolicy />} />
        <Route path="/" element={<Index />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
