import React from "react";

const Services = () => {
  return (
    <section id="services" className>
      {/* =============== container =============== */}
      <div className="container">
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-6 wow fadeInLeft animated"
            data-wow-delay=".5s"
          >
            <img
              src="assets/img/services-img.jpg"
              className="img-left"
              alt=""
            />
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-6 wow fadeInRight animated"
            data-wow-delay=".5s"
          >
            <h2>Our Services</h2>
            <span>
              Digitize is a critical part of designing in which art and science
              are used simultaneously. It will convert any artwork or design
              into embroidery format. Which is processed and fed to the machine
              for the final designs to be made in real.
            </span>
          </div>
        </div>
      </div>
      {/* =============== container end =============== */}
    </section>
  );
};

export default Services;
