import AboutUs from "../../components/AboutUs";
import Contacts from "../../components/Contacts";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Gallery from "../../components/Gallery/Gallery";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import PremiumServices from "../../components/PremiumServices";
import Services from "../../components/Services";
import WebDesign from "../../components/WebDesign";

import React from "react";
const Index = () => {
  return (
    <>
      <Navbar />
      <Header />
      <AboutUs />
      <PremiumServices />
      <Services />
      <WebDesign />
      <Container />
      <Gallery />
      <Contacts />
      <Footer />
    </>
  );
};

export default Index;
