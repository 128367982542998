import React from "react";

const WebDesign = () => {
  return (
    <section className="for-box">
      <div className="container">
        <div className="row">
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow rotateInDownLeft animated"
            data-wow-delay=".1s"
          >
            <div className="for-box-crecl">
              <img src="assets/img/tshirt.png" alt="t-shirt" />
            </div>
            <div className="clr1">
              <h2>
                Left Chest Logo <br />
                Digitizing
              </h2>
            </div>
            <div className="wbox">
              <p>
                A logo digitizing is done when teams of professionals design
                some amazing and...
              </p>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow rotateInDownLeft animated"
            data-wow-delay=".2s"
          >
            <div className="for-box-crecl">
              <img src="assets/img/cap.png" alt="Cap" />
            </div>
            <div className="clr2">
              <h2>
                Caps <br />
                Digitizing
              </h2>
            </div>
            <div className="wbox">
              <p>
                It is carried out a bit differently because of the curved nature
                of the cap and...
              </p>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow rotateInDownRight animated"
            data-wow-delay=".3s"
          >
            <div className="for-box-crecl">
              <img src="assets/img/3d.png" alt="3d puff" />
            </div>
            <div className="clr3">
              <h2>
                3D PUFF <br />
                Digitizing
              </h2>
            </div>
            <div className="wbox">
              <p>
                We know that simple embroidery designs are beautiful. 3D Puff
                embroidery...
              </p>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow rotateInDownRight animated"
            data-wow-delay=".4s"
          >
            <div className="for-box-crecl">
              <img src="assets/img/towel.png" alt="towel" />
            </div>
            <div className="clr2">
              <h2>
                Towel <br />
                Digitizing
              </h2>
            </div>
            <div className="wbox">
              <p>
                Having a wardrobe with the latest clothes is everyone’s dream.
                Branded apparel is all...
              </p>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow rotateInDownLeft animated"
            data-wow-delay=".1s"
          ></div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow rotateInDownLeft animated"
            data-wow-delay=".1s"
          >
            <div className="for-box-crecl">
              <img src="assets/img/puffer-jacket.png" alt="jacket" />
            </div>
            <div className="clr1">
              <h2>
                Jacket Back <br />
                Digitizing
              </h2>
            </div>
            <div className="wbox">
              <p>
                Jacket back digitization is one unique component. The images are
                converted to pictures and...
              </p>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-3 wow rotateInDownLeft animated"
            data-wow-delay=".2s"
          >
            <div className="for-box-crecl">
              <img src="assets/img/graphic-design.png" alt="vector" />
            </div>
            <div className="clr2">
              <h2>
                Vector Art <br />
                Services
              </h2>
            </div>
            <div className="wbox">
              <p>
                Vectorization is a process ,where image elements of one image of
                Single path are...
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebDesign;
