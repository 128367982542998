import React from "react";

const Header = () => {
  return (
    <header id="home" className="header">
      {/* =============== container =============== */}
      <div className="container">
        <div className="header-content row">
          <div id="owl-demo" className="owl-carousel header1">
            <div>
              <div className="col-xs-12 col-sm-6 col-md-6 header-text">
                <h2 className="wow bounceIn animated" data-wow-delay=".40s">
                  DIGITIZING <br />
                  <span>SERVICES</span>
                </h2>
                <h3 className="wow bounceIn animated" data-wow-delay=".50s">
                  Fulfill Everyone’s Good Quality Needs{" "}
                </h3>
                <p className="wow bounceIn animated" data-wow-delay=".60s">
                  Digitize is a critical part of designing in which art and
                  science are used simultaneously. It will convert any artwork
                  or design into embroidery format. Which is processed and fed
                  to the machine for the final designs to
                </p>
                <p></p>
                <a
                  className="btn btn-primary btn-lg btn-ornge wow bounceIn animated"
                  data-wow-delay="1s"
                  href={"/buy-now"}
                >
                  <i className="hbtn" /> <span>BUY NOW</span>
                </a>
                <p />
              </div>
            </div>
            <div>
              <div className="col-xs-12 col-sm-6 col-md-6 header-text">
                <h2 className="wow bounceIn animated" data-wow-delay=".40s">
                  QUALITY <br />
                  <span>ASSURANCE</span>{" "}
                </h2>
                <h3 className="wow bounceIn animated" data-wow-delay=".50s">
                  Because of Quality matters{" "}
                </h3>
                <p className="wow bounceIn animated" data-wow-delay=".60s">
                  We know your importance and we will never stop your faith in
                  us. That&apos;s why our professional will check the quality of
                  finished embroidery and make sure you will get the best of the
                  best final embroidery.
                </p>
                <p></p>
                <br />
                {/* <div
                  className="btn btn-primary btn-lg btn-ornge wow bounceIn animated"
                  data-wow-delay="1s"
                >
                  <i className="hbtn" /> <span>BUY NOW</span>
                </div> */}
                <p />
              </div>
            </div>
            <div>
              <div className="col-xs-12 col-sm-6 col-md-6 header-text">
                <h2 className="wow bounceIn animated" data-wow-delay=".40s">
                  VECTOR <br />
                  <span>SERVICES</span>{" "}
                </h2>
                <h3 className="wow bounceIn animated" data-wow-delay=".50s">
                  Satisfaction is our moto{" "}
                </h3>
                <p className="wow bounceIn animated" data-wow-delay=".60s">
                  Our professional will extract your idea and convert it into
                  your desire embroidery and vector design. We are one who focus
                  on client demands and their needs to fulfill requirements.
                </p>
                <p></p>
                <div
                  className="btn btn-primary btn-lg btn-ornge wow bounceIn animated"
                  data-wow-delay="1s"
                >
                  <i className="hbtn" /> <span>BUY NOW</span>
                </div>
                <p />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== container end =============== */}
    </header>
  );
};

export default Header;
